import React from "react";
//Import Icons
import chip from "../img/chip.jpg"
//Styles
import styled from "styled-components";
import { pallete } from "./Pallete";

const Footer = () =>{
    return(
        <div>
            <div>
                <Paralax >
                </Paralax>
            </div>
            <Container>
                <ContactContainer>  
                    <div>
                        <h4>Our contact</h4>
                    </div>
                    <ul>
                        <li>Nidex Limited OÜ</li>
                        <li>Ümera tn 5-58,</li>
                        <li>Lasnamäe linnaosa,</li>
                        <li>Harju Maakond,</li>
                        <li>Tallinn,13816,</li>
                        <li>info@nidex.eu</li>
                    </ul>
                </ContactContainer>
                <div>
                    <h2>Let's work</h2>
                    <h2> with <span>NIDEX</span> !</h2>
                </div>
            </Container>                    
        </div>
    )
}

const Paralax = styled.div`
    z-index:-1;
    width:100%;
    height:300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${chip});
    background-attachment:fixed;
`

const Container = styled.div`
    display:flex;
    padding: 5rem 10rem;
    justify-content: space-evenly;
    div{
        ul{
            list-style-type: none;
            font-size:1.2rem;
            color:${pallete.White}
            
        }
        h2,span{
            font-weight: 900;
            text-transform: uppercase;
        }
    }
    @media(max-width:768px){
        padding: 2rem 1rem 3rem;
        flex-direction:column-reverse;
        text-align:center;
        div{
            margin:1rem 0 0 0;
            ul{
                letter-spacing:0.5px;
            }
        } 
        h2,span{
            font-size:3.5rem;
        }
    }
    @media(max-width:360px){
        h2,span{
            font-size:2.5rem;
        }
    }
`
const ContactContainer = styled.div`
    margin-right:2rem;
    div{
        margin:1rem 0;
    }
`
export default Footer;