import React from "react";

//Styles
import { About } from "../styles";
import styled from "styled-components";
import { scrollReveal} from "../animation";
import { useScroll } from "./useScroll";


import ServicesSectionDescr from "./ServicesSectionDescr"

const ServicesSection = () => {
  const [element, controls] = useScroll();
  return (
    <Services
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      <Title >
        <h2>
          High <span>quality</span> 
        </h2>
        <h2>
          services
        </h2>
      </Title>
      <ServicesSectionDescr/>
    </Services>
  );
};

const Title = styled.div`
  margin: 0 5rem 3rem 0;
  h2, span{
    font-weight: 900;
    text-transform: uppercase;
  }
  @media (max-width: 1300px) {
    margin: 0 0rem 3rem 0;
  }
  @media (max-width: 600px) {
    h2, span{
      font-size:8vw;
    }
  }
`

const Services = styled(About)` 
 align-items:start;
 z-index:10;
   p {
    width: 70%;
    padding: 2rem 0rem 4rem 0rem;
  }
  @media (max-width: 1024px) {
    flex-direction:column;
    align-items:center;
    text-align:center;
    padding: 2rem 3rem 2rem ;
  }

`;


export default ServicesSection;
