import React from "react";
import styled from "styled-components";

const ShopBoy = () =>{

    return(

    <SVG width="406" height="306" viewBox="0 0 406 306" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="storeMonoBlue 1" clipPath="url(#clip0_0_1)">
        <g id="background">
        <path id="Vector" opacity="0.41" d="M331.95 233.162C330.958 238.306 329.683 243.489 327.088 248.016C326.845 248.438 326.603 248.852 326.306 249.251V249.071L69.5274 248.368V249.478C68.7456 228.854 64.0549 208.457 64.0001 187.81C63.9829 175.883 65.5337 164.005 68.6127 152.481C71.5131 141.63 75.8208 131.029 82.4894 121.992C100.689 97.3104 132.688 88.1166 158.604 71.7303C186.178 54.3042 195.771 15.1835 226.073 3.12049C242.045 -3.25891 260.73 -2.07059 276.131 5.57531C291.532 13.2212 303.626 27.0745 309.865 43.1012C317.855 63.6544 316.424 86.4514 318.981 108.349C322.202 136.025 331.872 162.605 334.218 190.312C335.432 204.632 334.669 219.05 331.95 233.162V233.162Z" fill="#CCCCCC" fillOpacity="0.6"/>
        <path id="Vector_2" opacity="0.66" d="M326.337 249.103V249.431C309.419 283.689 269.72 303.421 251.215 303.906L121.899 305C92.4338 305 69.4648 280.39 69.4648 250.924V249.478V248.368L326.337 249.103Z" fill="#CCCCCC" fillOpacity="0.6"/>
        <path id="Vector_3" d="M106.906 262.556C105.39 263.135 103.545 264.503 104.28 265.949C104.606 266.457 105.085 266.848 105.648 267.067C121.096 275.213 139.186 276.229 156.628 277.089L224.644 280.412C235.456 280.936 246.761 281.374 256.58 276.831C260.318 274.967 263.865 272.744 267.173 270.194C271.864 266.817 276.502 263.395 281.089 259.929C282.238 259.069 283.434 258.068 283.692 256.653C283.95 255.027 282.692 253.464 281.175 252.815C279.608 252.28 277.943 252.093 276.297 252.268C261.271 252.789 246.239 253 231.203 252.901C221.665 252.846 212.096 252.682 202.629 251.478C187.212 249.516 172.514 247.413 156.957 249.367C139.898 251.501 122.886 256.419 106.906 262.556Z" fill="url(#paint0_linear_0_1)"/>
        </g>
        <g id="mobile">
        <path id="Vector_4" d="M269.214 45.0779H178.729C170.724 45.0779 164.235 51.5673 164.235 59.5723V238.735C164.235 246.74 170.724 253.229 178.729 253.229H269.214C277.219 253.229 283.708 246.74 283.708 238.735V59.5723C283.708 51.5673 277.219 45.0779 269.214 45.0779Z" fill="#417A22"/>
        <path id="Vector_5" d="M261.779 45.0779H171.295C163.29 45.0779 156.8 51.5673 156.8 59.5723V238.735C156.8 246.74 163.29 253.229 171.295 253.229H261.779C269.784 253.229 276.273 246.74 276.273 238.735V59.5723C276.273 51.5673 269.784 45.0779 261.779 45.0779Z" fill="#76C64B"/>
        <path id="Vector_6" d="M267.259 68.9069V222.067C267.259 225.12 266.047 228.048 263.889 230.207C261.731 232.367 258.804 233.581 255.751 233.583H177.885C176.373 233.583 174.876 233.285 173.479 232.706C172.083 232.127 170.814 231.279 169.745 230.21C168.676 229.14 167.829 227.871 167.251 226.473C166.673 225.076 166.376 223.579 166.377 222.067V68.9069C166.376 67.6615 166.62 66.4282 167.096 65.2772C167.572 64.1263 168.269 63.0803 169.149 62.199C170.029 61.3177 171.074 60.6183 172.224 60.1408C173.374 59.6633 174.607 59.417 175.852 59.416H257.768C260.285 59.418 262.698 60.4186 264.477 62.1981C266.256 63.9775 267.257 66.3904 267.259 68.9069Z" fill="#F4F4F4"/>
        <path id="Vector_7" d="M220.344 238.453H203.708C201.264 238.453 199.283 240.435 199.283 242.878C199.283 245.322 201.264 247.303 203.708 247.303H220.344C222.788 247.303 224.769 245.322 224.769 242.878C224.769 240.435 222.788 238.453 220.344 238.453Z" fill="url(#paint1_linear_0_1)"/>
        </g>
        <g id="table">
        <path id="Vector_8" d="M267.259 171.196V222.067C267.259 225.12 266.047 228.048 263.889 230.207C261.731 232.367 258.804 233.581 255.751 233.583H187.603V171.196H267.259Z" fill="#68E1FD"/>
        <path id="Vector_9" d="M267.251 171.196V222.067C267.249 225.118 266.037 228.043 263.882 230.202C261.726 232.361 258.802 233.577 255.751 233.583H187.603V171.196H267.251Z" fill="url(#paint2_linear_0_1)"/>
        <path id="Vector_10" d="M267.251 200.537V222.067C267.256 225.022 266.119 227.865 264.077 230.002H190.081V200.537H267.251Z" fill="url(#paint3_linear_0_1)"/>
        </g>
        <g id="roof">
        <path id="Vector_11" d="M165.197 59.5176L140.875 85.5277H248.864L270.535 59.1501L165.197 59.5176Z" fill="#00CFFF"/>
        <path id="Vector_12" d="M178.534 59.416L156.745 85.3714L249.059 85.4652H140.875L141.532 84.8476L150.233 76.6935L166.307 59.4081L178.534 59.416Z" fill="url(#paint4_linear_0_1)"/>
        <path id="Vector_13" d="M210.509 59.0407L188.869 85.3713L281.183 85.4652H172.999L173.656 84.8475L194.24 59.4081L210.509 59.0407Z" fill="url(#paint5_linear_0_1)"/>
        <path id="Vector_14" d="M241.484 59.0407L219.844 85.3713L203.973 85.4652L204.63 84.8475L225.215 59.4081L241.484 59.0407Z" fill="url(#paint6_linear_0_1)"/>
        <path id="Vector_15" d="M270.691 59.0407L249.051 85.3713L233.181 85.4652L233.838 84.8475L254.422 59.4081L270.691 59.0407Z" fill="url(#paint7_linear_0_1)"/>
        <path id="Vector_16" d="M188.611 85.5199H204.098C203.839 87.3892 202.912 89.1015 201.489 90.3408C200.065 91.5801 198.242 92.2627 196.355 92.2627C194.468 92.2627 192.644 91.5801 191.221 90.3408C189.798 89.1015 188.871 87.3892 188.611 85.5199V85.5199Z" fill="#00CFFF"/>
        <path id="Vector_17" d="M172.882 85.5199H188.369C188.109 87.3892 187.182 89.1015 185.759 90.3408C184.336 91.5801 182.512 92.2627 180.625 92.2627C178.738 92.2627 176.915 91.5801 175.491 90.3408C174.068 89.1015 173.141 87.3892 172.882 85.5199V85.5199Z" fill="#00CFFF"/>
        <path id="Vector_18" d="M235.518 85.4261C235.276 87.3028 234.361 89.0277 232.943 90.281C231.525 91.5342 229.701 92.2307 227.809 92.2412C225.916 92.2517 224.084 91.5755 222.653 90.3381C221.221 89.1006 220.287 87.3859 220.023 85.5121H235.425L235.518 85.4261Z" fill="#00CFFF"/>
        <path id="Vector_19" d="M248.981 85.4261C248.606 89.335 245.729 92.3605 242.242 92.3605C238.755 92.3605 235.917 89.3663 235.511 85.5121H248.926L248.981 85.4261Z" fill="#68E1FD"/>
        <path id="Vector_20" d="M157.246 85.5199H172.733C172.474 87.3892 171.547 89.1015 170.123 90.3408C168.7 91.5801 166.877 92.2627 164.989 92.2627C163.102 92.2627 161.279 91.5801 159.855 90.3408C158.432 89.1015 157.505 87.3892 157.246 85.5199V85.5199Z" fill="#68E1FD"/>
        <path id="Vector_21" d="M204.317 85.5199H219.789C219.529 87.3892 218.602 89.1015 217.179 90.3408C215.756 91.5801 213.933 92.2627 212.045 92.2627C210.158 92.2627 208.335 91.5801 206.911 90.3408C205.488 89.1015 204.561 87.3892 204.302 85.5199H204.317Z" fill="#68E1FD"/>
        <path id="Vector_22" d="M141.501 85.5199H156.988C156.728 87.3892 155.801 89.1015 154.378 90.3408C152.955 91.5801 151.131 92.2627 149.244 92.2627C147.357 92.2627 145.533 91.5801 144.11 90.3408C142.687 89.1015 141.76 87.3892 141.501 85.5199V85.5199Z" fill="#68E1FD"/>
        <path id="Vector_23" d="M188.611 85.5199H204.098C203.839 87.3892 202.912 89.1015 201.489 90.3408C200.065 91.5801 198.242 92.2627 196.355 92.2627C194.468 92.2627 192.644 91.5801 191.221 90.3408C189.798 89.1015 188.871 87.3892 188.611 85.5199V85.5199Z" fill="url(#paint8_linear_0_1)"/>
        <path id="Vector_24" d="M172.882 85.5199H188.369C188.109 87.3892 187.182 89.1015 185.759 90.3408C184.336 91.5801 182.512 92.2627 180.625 92.2627C178.738 92.2627 176.915 91.5801 175.491 90.3408C174.068 89.1015 173.141 87.3892 172.882 85.5199V85.5199Z" fill="url(#paint9_linear_0_1)"/>
        <path id="Vector_25" d="M235.511 85.5199C235.268 87.3966 234.353 89.1215 232.935 90.3748C231.517 91.6281 229.693 92.3245 227.801 92.335C225.908 92.3455 224.077 91.6694 222.645 90.4319C221.213 89.1945 220.279 87.4798 220.016 85.6059H235.425L235.511 85.5199Z" fill="url(#paint10_linear_0_1)"/>
        <path id="Vector_26" d="M248.934 85.473C248.567 89.3819 245.697 92.4153 242.218 92.4153C238.739 92.4153 235.917 89.4132 235.511 85.5668H248.879L248.934 85.473Z" fill="url(#paint11_linear_0_1)"/>
        <path id="Vector_27" d="M157.246 85.5199H172.733C172.474 87.3892 171.547 89.1015 170.123 90.3408C168.7 91.5801 166.877 92.2627 164.989 92.2627C163.102 92.2627 161.279 91.5801 159.855 90.3408C158.432 89.1015 157.505 87.3892 157.246 85.5199V85.5199Z" fill="url(#paint12_linear_0_1)"/>
        <path id="Vector_28" d="M204.317 85.5199H219.789C219.529 87.3892 218.602 89.1015 217.179 90.3408C215.756 91.5801 213.933 92.2627 212.045 92.2627C210.158 92.2627 208.335 91.5801 206.911 90.3408C205.488 89.1015 204.561 87.3892 204.302 85.5199H204.317Z" fill="url(#paint13_linear_0_1)"/>
        <path id="Vector_29" d="M141.501 85.5199H156.988C156.728 87.3892 155.801 89.1015 154.378 90.3408C152.955 91.5801 151.131 92.2627 149.244 92.2627C147.357 92.2627 145.533 91.5801 144.11 90.3408C142.687 89.1015 141.76 87.3892 141.501 85.5199V85.5199Z" fill="url(#paint14_linear_0_1)"/>
        <path id="Vector_30" d="M156.98 85.5199L178.385 59.6036L194.24 59.4081L172.686 85.5199H156.98Z" fill="url(#paint15_linear_0_1)"/>
        <path id="Vector_31" d="M188.47 85.5199L209.876 59.6036L224.761 59.2674L204.177 85.5199H188.47Z" fill="url(#paint16_linear_0_1)"/>
        <path id="Vector_32" d="M219.961 85.5199L241.358 59.6036L256.252 59.2674L235.659 85.5199H219.961Z" fill="url(#paint17_linear_0_1)"/>
        <path id="Vector_33" d="M248.879 85.5668L269.644 81.4546L270.769 59.0407L248.879 85.5668Z" fill="#68E1FD"/>
        <path id="Vector_34" d="M249.051 85.3479L269.816 81.2357L270.949 58.8218L249.051 85.3479Z" fill="url(#paint18_linear_0_1)"/>
        </g>
        <g id="bag">
        <path id="Vector_35" d="M159.755 147.578C171.107 147.578 180.309 138.376 180.309 127.025C180.309 115.674 171.107 106.472 159.755 106.472C148.404 106.472 139.202 115.674 139.202 127.025C139.202 138.376 148.404 147.578 159.755 147.578Z" fill="#3AA501"/>
        <path id="Vector_36" d="M159 147C169.493 147 178 138.046 178 127C178 115.954 169.493 107 159 107C148.507 107 140 115.954 140 127C140 138.046 148.507 147 159 147Z" fill="url(#paint19_linear_0_1)"/>
        <path id="Vector_37" d="M167.698 138.869H147.974L151.32 121.029H164.837L167.698 138.869Z" fill="white"/>
        <path id="Vector_38" d="M162.531 122.608H154.072V121.826C154.072 120.704 154.517 119.629 155.311 118.835C156.104 118.042 157.18 117.597 158.301 117.597C159.423 117.597 160.499 118.042 161.292 118.835C162.085 119.629 162.531 120.704 162.531 121.826V122.608ZM155.682 121.083H160.889C160.724 120.521 160.381 120.027 159.912 119.675C159.443 119.323 158.872 119.133 158.286 119.133C157.699 119.133 157.129 119.323 156.659 119.675C156.19 120.027 155.847 120.521 155.682 121.083Z" fill="white"/>
        </g>
        <g id="percent">
        <path id="Vector_39" d="M136.169 188.857C146.22 188.857 154.369 180.708 154.369 170.657C154.369 160.605 146.22 152.457 136.169 152.457C126.117 152.457 117.969 160.605 117.969 170.657C117.969 180.708 126.117 188.857 136.169 188.857Z" fill="#3AA501"/>
        <g id="Group 1">
        <path id="Vector_40" d="M135 189C144.389 189 152 180.941 152 171C152 161.059 144.389 153 135 153C125.611 153 118 161.059 118 171C118 180.941 125.611 189 135 189Z" fill="url(#paint20_linear_0_1)"/>
        </g>
        <path id="Vector_41" d="M133.925 167.272C133.925 171.134 131.9 173.041 129.531 173.041C127.163 173.041 125.286 171.212 125.255 167.569C125.224 163.926 127.256 161.854 129.672 161.854C132.229 161.815 133.925 163.785 133.925 167.272ZM127.225 167.444C127.17 169.789 128.046 171.556 129.57 171.556C131.095 171.556 131.916 169.805 131.916 167.389C131.916 165.161 131.267 163.308 129.57 163.308C128.046 163.308 127.225 165.169 127.225 167.444ZM129.367 180.734L140.117 161.822H141.68L130.939 180.726L129.367 180.734ZM145.832 174.855C145.832 178.709 143.799 180.625 141.469 180.625C139.14 180.625 137.216 178.795 137.193 175.152C137.169 171.509 139.186 169.414 141.61 169.414C144.166 169.39 145.832 171.353 145.832 174.847V174.855ZM139.132 175.019C139.132 177.364 140.007 179.131 141.524 179.131C143.158 179.131 143.869 177.388 143.869 174.964C143.869 172.744 143.221 170.883 141.524 170.883C139.976 170.876 139.132 172.705 139.132 175.011V175.019Z" fill="white"/>
        </g>
        <g id="man">
        <path id="Vector_42" d="M222.986 148.587C220.438 151.323 218.96 154.841 217.514 158.312L213.441 168.178C213.309 168.403 213.229 168.655 213.206 168.914C213.183 169.174 213.218 169.435 213.308 169.679C213.462 169.875 213.658 170.035 213.881 170.147C214.104 170.259 214.348 170.321 214.598 170.328C216.671 170.642 218.758 170.851 220.852 170.954C231.427 171.61 242.02 171.853 252.632 171.681C253.904 171.748 255.175 171.532 256.353 171.048C259.277 169.57 259.48 165.505 258.988 162.276C258.397 158.513 257.347 154.838 255.861 151.331C255.339 149.941 254.571 148.657 253.593 147.539C251.17 144.998 247.339 144.592 243.813 144.49C237.121 144.326 228.006 143.185 222.986 148.587Z" fill="#68E1FD"/>
        <path id="Vector_43" d="M215.208 146.327C215.141 145.969 214.945 145.648 214.656 145.426C214.368 145.204 214.007 145.096 213.644 145.123C212.723 145.083 211.8 145.117 210.884 145.225C210.296 145.241 209.729 145.453 209.274 145.827C208.945 146.225 208.741 146.712 208.688 147.226C207.945 151.253 208.805 155.381 209.133 159.461C209.36 162.245 209.383 165.2 210.884 167.545C211.563 168.583 212.491 169.433 213.584 170.019C214.677 170.605 215.899 170.908 217.139 170.899C218.186 170.899 219.375 170.516 219.773 169.554C219.935 169.069 219.981 168.552 219.906 168.046C219.367 160.72 218.311 153.066 215.208 146.327Z" fill="#68E1FD"/>
        <path id="Vector_44" d="M214.676 142.739C215.169 141.676 215.841 140.706 216.427 139.69C216.594 139.435 216.703 139.147 216.748 138.846C216.766 138.69 216.738 138.532 216.665 138.392C216.593 138.253 216.48 138.138 216.341 138.064C215.755 137.837 215.286 138.65 214.676 138.775C214.066 138.9 213.628 138.416 213.23 137.994C211.491 136.262 209.437 134.88 207.179 133.92C207.062 133.851 206.933 133.805 206.799 133.786C206.665 133.767 206.528 133.776 206.397 133.811C206.155 133.92 206.084 134.35 206.334 134.429C206.092 134.447 205.851 134.387 205.646 134.257C205.427 134.163 205.107 134.155 205.005 134.374C204.904 134.593 205.201 134.913 205.123 135.156C204.857 135.162 204.592 135.12 204.341 135.031C204.075 134.984 203.723 135.031 203.676 135.335C203.656 135.436 203.656 135.54 203.676 135.64C203.711 136.049 203.711 136.459 203.676 136.868C203.799 137.959 204.255 138.985 204.982 139.807C206.431 141.611 207.738 143.524 208.891 145.53C209.167 146.185 209.539 146.796 209.993 147.344C210.634 148.032 213.011 149.009 213.636 147.813C213.933 147.25 213.691 146.015 213.8 145.358C213.976 144.45 214.27 143.57 214.676 142.739V142.739Z" fill="url(#paint21_linear_0_1)"/>
        <path id="Vector_45" d="M246.933 159.25C247.086 159.746 247.128 160.269 247.056 160.782C246.985 161.295 246.801 161.787 246.518 162.221C245.838 163.05 244.65 163.214 243.579 163.269C238.364 163.542 233.04 162.643 227.943 163.832C227.211 163.925 226.535 164.274 226.035 164.817C225.6 165.588 225.474 166.497 225.684 167.358L226.121 171.087L221.04 170.164C220.849 170.139 220.666 170.075 220.5 169.977C220.26 169.744 220.117 169.428 220.102 169.093C219.685 167.149 219.504 165.162 219.562 163.175C219.63 161.171 220.418 159.258 221.782 157.788C223.075 156.641 224.634 155.835 226.317 155.443C230.226 154.317 234.338 153.989 238.411 153.661C240.17 153.52 243.102 153.129 244.611 154.325C245.869 155.341 246.62 157.757 246.933 159.25Z" fill="url(#paint22_linear_0_1)"/>
        <path id="Vector_46" d="M225.418 132.333C225.278 133.61 225.323 134.9 225.551 136.164C225.826 137.273 226.362 138.301 227.114 139.161C227.866 140.022 228.812 140.691 229.874 141.113C231.438 141.731 233.29 141.895 234.361 143.169C235.433 144.443 235.378 146.14 236.48 147.203C237.052 147.682 237.767 147.957 238.512 147.987C239.257 148.017 239.992 147.799 240.6 147.367C241.801 146.46 242.605 145.124 242.844 143.638C243.067 142.166 243.153 140.677 243.102 139.19C243.125 137.205 243.29 135.225 243.594 133.264C243.86 131.899 244.025 130.517 244.087 129.128C244.074 127.521 243.764 125.931 243.172 124.437C241.851 120.958 239.13 117.823 235.526 116.901C233.879 116.494 232.144 116.623 230.575 117.27C229.006 117.917 227.684 119.047 226.802 120.497C225.895 121.959 225.981 123.444 225.871 125.188C225.725 127.559 225.574 129.941 225.418 132.333Z" fill="url(#paint23_linear_0_1)"/>
        <path id="Vector_47" d="M224.394 112.327C223.745 112.062 222.869 111.819 222.455 112.382C222.313 112.617 222.245 112.89 222.259 113.164C222.247 114.155 222.579 115.119 223.198 115.892C222.55 115.846 221.908 116.038 221.392 116.432C220.665 117.151 221.024 118.418 221.665 119.207C222.306 119.997 223.19 120.638 223.573 121.553C223.776 122.045 223.823 122.592 224.018 123.116C224.093 123.373 224.24 123.603 224.44 123.781C224.641 123.958 224.888 124.074 225.152 124.117C225.856 124.117 226.301 123.413 226.716 122.827C227.821 121.323 229.46 120.3 231.296 119.967C233.133 119.634 235.027 120.017 236.59 121.037C239.607 123.179 240.444 127.221 241.046 130.871C241.351 131.09 241.53 130.504 241.648 130.152C241.914 129.37 243.133 129.441 243.571 130.105C243.753 130.46 243.833 130.858 243.803 131.255C243.773 131.653 243.633 132.034 243.399 132.357C242.977 133.029 242.359 133.561 241.898 134.21C241.763 134.362 241.693 134.561 241.702 134.765C241.743 134.935 241.839 135.086 241.976 135.195C242.35 135.568 242.747 135.918 243.164 136.242C243.317 136.395 243.501 136.514 243.703 136.589C243.905 136.665 244.122 136.696 244.337 136.68C244.631 136.596 244.889 136.417 245.072 136.172C246.636 134.421 247.245 131.989 247.582 129.652C247.918 127.314 247.894 124.68 246.08 123.272C245.721 122.999 245.299 122.796 244.97 122.491C244.384 121.91 243.903 121.233 243.548 120.489C241.596 117.206 238.589 114.682 235.018 113.328C233.41 112.574 231.611 112.323 229.858 112.609C228.059 113.146 226.129 113.047 224.394 112.327V112.327Z" fill="#B7A451"/>
        <path id="Vector_48" d="M215.02 169.601C214.746 169.945 214.496 170.469 214.801 170.782C214.908 170.857 215.028 170.911 215.156 170.939C215.283 170.967 215.415 170.97 215.544 170.946L224.878 170.391C225.03 170.392 225.181 170.366 225.324 170.313C225.793 170.102 225.824 169.468 225.793 168.952L225.629 166.544C225.574 165.677 225.754 165.411 224.91 165.294C223.64 165.237 222.37 165.415 221.165 165.817C218.778 166.421 216.634 167.742 215.02 169.601V169.601Z" fill="url(#paint24_linear_0_1)"/>
        </g>
        <g id="figures">
        <path id="Vector_49" d="M267.251 173.995H190.05V196.886H267.251V173.995Z" fill="url(#paint25_linear_0_1)"/>
        <path id="Vector_50" d="M210.267 180.515H195.467V196.589H210.267V180.515Z" fill="#68E1FD"/>
        <path id="Vector_51" d="M210.267 180.515H195.467V196.589H210.267V180.515Z" fill="url(#paint26_linear_0_1)"/>
        <path id="Vector_62" d="M254.102 196.768C257.936 196.768 261.044 193.66 261.044 189.826C261.044 185.992 257.936 182.884 254.102 182.884C250.268 182.884 247.159 185.992 247.159 189.826C247.159 193.66 250.268 196.768 254.102 196.768Z" fill="#68E1FD"/>
        <path id="Vector_63" d="M250.083 229.166C253.917 229.166 257.026 226.058 257.026 222.224C257.026 218.389 253.917 215.281 250.083 215.281C246.249 215.281 243.141 218.389 243.141 222.224C243.141 226.058 246.249 229.166 250.083 229.166Z" fill="url(#paint31_linear_0_1)"/>
        <path id="Vector_64" d="M247.527 229.166C251.361 229.166 254.469 226.058 254.469 222.224C254.469 218.389 251.361 215.281 247.527 215.281C243.693 215.281 240.585 218.389 240.585 222.224C240.585 226.058 243.693 229.166 247.527 229.166Z" fill="#68E1FD"/>
        </g>
        </g>
        <defs>
        <linearGradient id="paint0_linear_0_1" x1="197.383" y1="208.245" x2="191.355" y2="299.214" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_0_1" x1="210.884" y1="223.826" x2="213.363" y2="265.245" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_0_1" x1="229.655" y1="263.322" x2="224.878" y2="132.701" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear_0_1" x1="225.261" y1="187.966" x2="236.371" y2="277.402" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear_0_1" x1="197.524" y1="52.8098" x2="193.044" y2="109.998" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint5_linear_0_1" x1="229.686" y1="52.3486" x2="225.144" y2="110.248" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint6_linear_0_1" x1="224.511" y1="49.5185" x2="219.977" y2="107.418" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint7_linear_0_1" x1="253.719" y1="49.5185" x2="249.176" y2="107.418" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint8_linear_0_1" x1="193.951" y1="63.3953" x2="199.634" y2="115.681" gradientUnits="userSpaceOnUse">
        <stop offset="0.02" stopColor="white" stopOpacity="0"/>
        <stop offset="0.32" stopColor="white" stopOpacity="0.39"/>
        <stop offset="0.68" stopColor="white" stopOpacity="0.68"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint9_linear_0_1" x1="178.432" y1="65.0839" x2="184.116" y2="117.37" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint10_linear_0_1" x1="224.996" y1="60.1039" x2="230.679" y2="112.398" gradientUnits="userSpaceOnUse">
        <stop offset="0.02" stopColor="white" stopOpacity="0"/>
        <stop offset="0.32" stopColor="white" stopOpacity="0.39"/>
        <stop offset="0.68" stopColor="white" stopOpacity="0.68"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint11_linear_0_1" x1="239.513" y1="60.5652" x2="245.103" y2="112.023" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint12_linear_0_1" x1="162.906" y1="66.7648" x2="168.59" y2="119.059" gradientUnits="userSpaceOnUse">
        <stop offset="0.02" stopColor="white" stopOpacity="0"/>
        <stop offset="0.32" stopColor="white" stopOpacity="0.39"/>
        <stop offset="0.68" stopColor="white" stopOpacity="0.68"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint13_linear_0_1" x1="209.477" y1="61.7066" x2="215.161" y2="113.993" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint14_linear_0_1" x1="147.387" y1="68.4534" x2="153.071" y2="120.747" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint15_linear_0_1" x1="156.98" y1="72.464" x2="194.24" y2="72.464" gradientUnits="userSpaceOnUse">
        <stop offset="0.02" stopColor="white" stopOpacity="0"/>
        <stop offset="0.32" stopColor="white" stopOpacity="0.39"/>
        <stop offset="0.68" stopColor="white" stopOpacity="0.68"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint16_linear_0_1" x1="188.47" y1="72.3936" x2="224.761" y2="72.3936" gradientUnits="userSpaceOnUse">
        <stop offset="0.02" stopColor="white" stopOpacity="0"/>
        <stop offset="0.32" stopColor="white" stopOpacity="0.39"/>
        <stop offset="0.68" stopColor="white" stopOpacity="0.68"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint17_linear_0_1" x1="219.961" y1="72.3936" x2="256.252" y2="72.3936" gradientUnits="userSpaceOnUse">
        <stop offset="0.02" stopColor="white" stopOpacity="0"/>
        <stop offset="0.32" stopColor="white" stopOpacity="0.39"/>
        <stop offset="0.68" stopColor="white" stopOpacity="0.68"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint18_linear_0_1" x1="283.208" y1="83.4403" x2="241.968" y2="63.2624" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint19_linear_0_1" x1="140" y1="127" x2="177.993" y2="127" gradientUnits="userSpaceOnUse">
        <stop offset="0.02" stopColor="white" stopOpacity="0"/>
        <stop offset="0.32" stopColor="white" stopOpacity="0.19"/>
        <stop offset="0.68" stopColor="white" stopOpacity="0.46"/>
        <stop offset="1" stopColor="white" stopOpacity="0.79"/>
        </linearGradient>
        <linearGradient id="paint20_linear_0_1" x1="118" y1="171" x2="152" y2="171" gradientUnits="userSpaceOnUse">
        <stop offset="0.02" stopColor="white" stopOpacity="0"/>
        <stop offset="0.32" stopColor="white" stopOpacity="0.14"/>
        <stop offset="0.6799" stopColor="white" stopOpacity="0.437569"/>
        <stop offset="0.68" stopColor="white" stopOpacity="0.44"/>
        <stop offset="1" stopColor="white" stopOpacity="0.83"/>
        </linearGradient>
        <linearGradient id="paint21_linear_0_1" x1="203.661" y1="141.136" x2="216.748" y2="141.136" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E5C5D5"/>
        <stop offset="0.42" stopColor="#EAD4D1"/>
        <stop offset="1" stopColor="#F0E9CB"/>
        </linearGradient>
        <linearGradient id="paint22_linear_0_1" x1="230.234" y1="151.644" x2="241.898" y2="196.026" gradientUnits="userSpaceOnUse">
        <stop stopOpacity="0"/>
        <stop offset="0.99"/>
        </linearGradient>
        <linearGradient id="paint23_linear_0_1" x1="225.585" y1="136.412" x2="244.896" y2="128.653" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E5C5D5"/>
        <stop offset="0.42" stopColor="#EAD4D1"/>
        <stop offset="1" stopColor="#F0E9CB"/>
        </linearGradient>
        <linearGradient id="paint24_linear_0_1" x1="214.66" y1="168.108" x2="225.801" y2="168.108" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E5C5D5"/>
        <stop offset="0.42" stopColor="#EAD4D1"/>
        <stop offset="1" stopColor="#F0E9CB"/>
        </linearGradient>
        <linearGradient id="paint25_linear_0_1" x1="225.824" y1="162.667" x2="235.151" y2="237.82" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint26_linear_0_1" x1="210.97" y1="186.41" x2="185.031" y2="193.258" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint27_linear_0_1" x1="229.303" y1="190.1" x2="205.873" y2="196.284" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint28_linear_0_1" x1="242.727" y1="185.057" x2="234.651" y2="205.743" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint29_linear_0_1" x1="237.981" y1="217.697" x2="229.897" y2="238.383" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint30_linear_0_1" x1="260.043" y1="181.156" x2="249.27" y2="208.738" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint31_linear_0_1" x1="253.468" y1="213.546" x2="242.695" y2="241.127" gradientUnits="userSpaceOnUse">
        <stop offset="0.01"/>
        <stop offset="0.13" stopOpacity="0.69"/>
        <stop offset="1" stopOpacity="0"/>
        </linearGradient>
        <clipPath id="clip0_0_1">
        <rect width="406" height="306" fill="white"/>
        </clipPath>
        </defs>
    </SVG>

    )
}

const SVG = styled.svg`
  #bag{
      animation: mobile 5s  infinite linear;
  }
    @keyframes mobile{

            50% {
                transform: translateY(0);
            }
            
            50% {
                transform: translateY(-30px);
            }

    }
    #percent{

        animation: percent 8s  infinite linear;
        }
        @keyframes percent{

            50% {
                transform: translateY(0);
                transform: translateY(0);
            }
            
            50% {
                transform: translateY(0);
                transform: translateY(30px);
            }

}
`
export default ShopBoy