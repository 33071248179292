//Import Images
import retail from "./img/retail.jpg"
import logistic from "./img/logistic.jpg"
import marketplaces from "./img/marketplaces.jpg"
import teamwork from "./img/teamwork.jpg"

import amazon from "./img/amazon.svg"
import allegro from "./img/allegro.svg"
import ceneo from "./img/ceneo.svg"
import ebay from "./img/ebay.svg"
import olx from "./img/olx.svg"


const ourWorkData =  [
    {
      title: "Retail",
      backImg: retail,
      description:"We are proud to deliver to our customer best prices available on the market. Our store on Amazon having over a thousand of unique visitors every day and our customers satisfaction rate is staying on average above 99%. Nidex Limited OU is committed to the highest standards of customer care.",
     
    },
    {
      id: "market",
      title: "Marketplaces",
      backImg: marketplaces,
      description:"Our main retail sales are conducted via our Amazon and Ebay stores. However, we are actively expanding our range of products and marketplaces we are working with.",
      markets:["alegro","olx","ebay","amazon","ceneo"],
      icons:[amazon,allegro,ceneo,ebay,olx]
    }, 
    {
      title: "Logistic",
      backImg: logistic,
      description:"Central European hub that is located in Tallin is simplifying logistics operation of both retail and wholesale arms of the Nidex Limited OU trading activities.",
     
    },
    {
      title: "Teamwork",
      backImg: teamwork,
      description:"Our team consists of both experts in International trade and Amazon sales. Based around the Europe with a main hub and office located in Tallin, Estonia, our team is covering all the aspects of successful operation of the company.",
     
    },
  ]
  export default ourWorkData
