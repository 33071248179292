import React from "react";
import styled from "styled-components";
import ourWorkData from "../ourWorkData"


const SectionWork = () => {

    return(
        <>
            {ourWorkData.map((elem,index ) => {
                if(elem.id === "market"){
                    return(
                        <div key={index}>
                            <Title  style = {{  backgroundImage: `url(${elem.backImg})` }}> 
                                <h2>{elem.title}</h2>
                            </Title>
                            <Description>
                                <p>{elem.description}</p>
                                <Marketplaces>
                                    <div>
                                        {elem.icons.map((icon,index) => (
                                            <img key={index} src={icon}/>
                                        ))}
                                    </div>
                                </Marketplaces>
                            </Description>
                        </div> 
                    )
                }else{
                    return(
                        <div key={index}>
                        <Title  style = {{  backgroundImage: `url(${elem.backImg})` }}> 
                            <h2>{elem.title}</h2>
                        </Title>
                        <Description>
                            <p>{elem.description}</p>
                        </Description>
                        </div> 
                    )
                }
            })}
        </>
    )
};

const Title = styled.div`
    z-index:-1;
    top:0;
    right:0;
    width:100%;
    height:200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment:fixed;
    background-position: center ;

    h2{
        text-align: center ;
        line-height:200px;
        font-size:4rem;
        font-weight: 900;
        text-transform: uppercase;
        text-shadow: 1px 1px 20px black;
        backdrop-filter:brightness(80%);
    }
    @media(max-width:450px){
        h2{
            font-size:8vw;
        }
    }
`
const Description = styled.div`
    padding: 5rem 10rem;
    @media(max-width:1300px){
        padding: 2rem 4rem;
    }
    @media(max-width:360px){
        padding: 1rem 2rem;
    }
`
const Marketplaces = styled.div`
    div{
        display:flex;
        justify-content:space-between;
        img{
            margin:1rem;
            width:200px;
            height:200px;
            object-fit: contain;
        }
    }
    @media(max-width:1300px){
        div{
            img{
                margin:0 1rem;
                width:15vw;
                height:15vw;
            }

        }
    }
    @media(max-width:800px){
        div{
            flex-wrap:wrap;
            justify-content:space-around;
            img{
                
            }
        }
    }
    @media(max-width:500px){
        div{
            img{
                width:80px;
                height:80px;
            }
        }
    }
    @media(max-width:290px){
        div{
            img{
                width:65px;
                height:65px;
            }
        }
    }
`

export default SectionWork ;
