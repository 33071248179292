import timeIcon from "./img/timeIcon.svg";
import teamIcon from "./img/teamIcon.svg";
import logisticIcon from "./img/logisticIcon.svg";
import shopIcon from "./img/shopIcon.svg";

const servicesSectionData = [
    {
        title:"Retail",
        img:shopIcon,
        description:"Delivering best wholesale prices directly to retail customers via our Amazon store",

    },
    {
        title:"Logistics",
        img:logisticIcon,
        description:"Central European location in Tallin, Estonia.",

    },
    {
        title:"Speed",
        img:timeIcon,
        description:"Most of our products are dispatched directly by Amazon via Amazon Prime",

    },
    {
        title:"Teamwork",
        img:teamIcon,
        description:"Over 50 years of combined experience in both Electronics trade and Amazon sales",

    },

]


export default servicesSectionData;