import React from "react";

import styled from "styled-components";
import { motion } from "framer-motion";

import TopSection from "./TopSection";
import {pageAnimWork} from "../animation";
import { pallete } from "./Pallete";


const ContactUs = () =>{

    return(
        <motion.div
        exit="exit"
        variants={pageAnimWork}
        initial="hidden"
        animate="show"
      >
            <TopSection h1prop={"Let's work"} styleDisplayOn={"none"} styleDisplayOff={"flex"}/>
            <Info>
                <div>
                    <div>
                        <h3>Please place your requests, offers or suggestions to :</h3>
                    </div>
                    <div>
                        <ul>
                            <li>Nidex Limited OÜ</li>
                            <li>Ümera tn 5-58,</li>
                            <li>Lasnamäe linnaosa,</li>
                            <li>Harju Maakond,</li>
                            <li>Tallinn,</li>
                            <li>13816,</li>
                            <li>info@nidex.eu<button onClick={() => {navigator.clipboard.writeText("info@nidex.eu") ; alert("Was copied!")}}>copy</button> </li>
                            <li>Visit our <a href="https://www.amazon.co.uk/sp?ie=UTF8&seller=A3LSAICVO4P94J&isAmazonFulfilled=0&asin=B08RX3343D&ref_=olp_merch_name_1"><button >STORE</button></a></li>
                        </ul>
                    </div>
                </div>
                <MapWrapper>
                        <iframe  src="https://maps.google.com/maps?q=%C3%9Cmera%20tn%205-58,%20Lasnam%C3%A4e%20linnaosa,%20Harju%20Maakond,%20Tallinn&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                </MapWrapper>
            </Info>
        </motion.div>
    )
    
}

const Info = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: 10rem 10rem;
    >div{
        width:40%;
        margin:0 2rem;
        h3{
            font-size:2rem;
            padding-bottom:1rem;
            background-color: #f3ec78;
            background-image:linear-gradient(to right, #204852 , #76c64b);
            background-size: 100%;
            -webkit-background-clip: text;
            -moz-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-text-fill-color: transparent;
        }
        ul{
            color:${pallete.White};       
            font-size:1.5rem;
            list-style-type: none;
            li{
                display:flex;
                align-items:center;
                margin:1rem 0;
            }
        }
    }
    button{
        padding:0.3rem 0.8rem;
        margin-left:1rem;
    }
    @media(max-width:1300px){
        flex-direction:column;
        div{
            display:flex;
            width:100%;
            justify-content:space-evenly;
            align-items:center;
        }
    }
    @media(max-width:1050px){
        padding:2rem 2rem;
        div{
            flex-direction:column;
            h3{
                margin-bottom:2rem;
            }
           
        }
    }
    @media(max-width:370px){
        div{
            flex-direction:column;
        }
    }
`

const MapWrapper = styled.div`
    iframe{
        width:500px;
        height:350px;
    }
    @media(max-width:1300px){
        iframe{
            margin-top:5rem;
            width:100vw;
            height:350px;
        }
    }
`
export default ContactUs;