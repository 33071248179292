import styled from "styled-components"
import { motion } from "framer-motion";

export const About = styled(motion.div)`
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 10rem;
    color: white;
    z-index:2;
    @media (max-width: 1280px) {
    padding: 3rem 3rem;
    }
    @media (max-width: 600px) {
      padding: 13rem 3rem 5rem ;
      flex-direction:column;
      text-align: center;
    }
    @media (max-width: 400px) {
      padding: 27vh 3rem 5rem;
    }
`;

export  const Description = styled.div`
    flex: 1;
    padding-right: 5rem;
    z-index: 2;
    h2{
        font-weight: lighter;
        font-size:3vw;
    }
    @media (max-width: 1300px) {
      div{
        p{
          font-size:1rem;
        }
      }
    }
    @media (max-width: 600px) {
      padding:0;
      h2{
        font-size:6vw;
      }
    }
    @media (max-width: 300px) {
      h2{
        font-size:5.6vw;
      }
      div{
        p{
          font-size:0.8rem;
        }
      }
    }


`
export const Image = styled.div`
  z-index: 2;
  flex: 1;
  svg{
    width: 100%;
    height: 80vh;
    object-fit: contain;
  }
  @media(max-width:600px){
    svg{
      display:none;
  }
  
  }
  
    
`
export const Hide = styled.div`
    overflow: hidden;
`
