export const pageAnimation = {
  hidden: {
    opacity: 0,
    
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.25,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};



export const imageSlideWork = {
  hidden: { x: window.screen.width < 450 ? -100 : -200 ,  opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
    transition: { ease: "easeOut", duration: 1.2 },
  },
};
export const pageAnimWork = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.7,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const fadeAninWork = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { ease: "easeOut", duration: 2 },
  },
};


export const titleAnim = {
  hidden: { y: 600 },
  show: {
    y: 0,
    transition: { duration: 0.75, ease: "easeOut" },
  },
};





export const fade = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { ease: "easeOut", duration: 0.75 },
  },
};

export const photoAnim = {
  hidden: { scale: 1.5, opacity: 0 },
  show: {
    scale: 1,
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 0.75,
    },
  },
};

export const scrollReveal = {
  hidden: { opacity: 0, scale: 1.2, transition: { duration: 0.5 } },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

export const servicesCards = {
  hidden: { opacity: 1,scale: 1.2, },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.2
    }
  }
};

export const servicesCardItem = {
  hidden: { y: 30, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
  },
  
}
