import React from "react";
// import styles
import styled from "styled-components";
import { pallete } from "./Pallete";
import { useState } from "react";
import { Link } from "react-router-dom";
import nidexnone from "../img/nidexnone.png"

const BurgerMenu = () =>{
    const [menuActive, SetMenuActive] = useState(false);
    return(
        <BurgerBox>
            <ButtonBurger  onClick={ () => SetMenuActive(!menuActive) } >
            
                <Line style={{
                    background: menuActive ? "white" : "linear-gradient(to right, #204852 , #76c64b)",
                    transform: menuActive ? "translateX(0) translateY(4px) rotate(45deg)" : "none" ,
                }}></Line>
                <Line style={{
                    background:  menuActive ? "white" : "linear-gradient(to right, #204852 , #76c64b)",
                    opacity:menuActive ? "0" : "1" ,}}></Line>
                <Line style={{
                    background:  menuActive ? "white" : "linear-gradient(to right, #204852 , #76c64b)",
                    transform: menuActive ? "translateX(0) translateY(-8px) rotate(-45deg)" : "none" ,
                }}></Line>
            </ButtonBurger>
            <BrugerContainer style={{
                top: menuActive ?  "0" : "-100vh",
                height:  menuActive ?  "100vh" : "0",
                opacity: menuActive ?  "1" : "0",
                }}>
                <UlBurgenCont>
                    <img src={nidexnone} alt="nidex_logo"/>
                    <li onClick={ () => SetMenuActive(!menuActive) }>
                        <Link  to="/">About Us</Link>
                        <hr/>
                    </li>
                    <li onClick={ () => SetMenuActive(!menuActive) }>
                        <Link to="/work">Our Work</Link>
                        <hr/>
                    </li>
                    <li onClick={ () => SetMenuActive(!menuActive) }>
                        <Link to="/contact">Contact Us</Link>
                        <hr/>
                    </li>
                </UlBurgenCont>
            </BrugerContainer>
        </BurgerBox>
    )
}

const BurgerBox = styled.div`
    box-sizing:border-box;
    display:none;
    position: sticky !important;
    @media (max-width:380px){
        display:block;    
    }
    z-index:8 !important;
`
const ButtonBurger = styled.button`
    position: fixed;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    align-items: center;
    top: 2rem;
    right: -12px;
    cursor: pointer;
    border: none;
    background: none;
    z-index:10 !important;
    -webkit-tap-highlight-color:  transparent;
    &:hover{
        background:none !important;
        -webkit-tap-highlight-color:  transparent;
    }
`

const Line = styled.div`
    width: 21px;
    height: 2px;
    align-items: center;
    border-radius: 5px;
    margin: 4px 0;
    transition: 0.3s all ease;
`


const BrugerContainer = styled.div`  
    background-image:linear-gradient(to right, #204852 , #76c64b);
    transition: 0.5s all ease;
    @media (max-width:380px){
        display:block;
        position: fixed !important;
        width:100%;    
    }
`


const UlBurgenCont = styled.ul`
    margin-top:80px;
    display:flex
    flex-direction:column;
    list-style: none;
    text-align:center;
    width:100%;
    img{
        width:35%;
        height:auto;
        margin-bottom:30px;
    }
    li{
        margin-left:2rem;
        justify-content:center;
        text-align:left;
        cursor:pointer;
        padding:2rem 0;
        a{
            color:${pallete.White};
            font-size:2rem;
            margin-bottom:25px;
            transition:0.3s;
            text-decoration: none;
        }
        hr{
            margin-right:2rem;
        }
    }
    li:hover{
        a{
            color:${pallete.UltraGreen};
        }
    } 

`


export default BurgerMenu;
