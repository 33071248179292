import React from "react";
// Import Style
import GlobalStyle from "./components/GlobalStyle";
// Import components
import Nav from "./components/Nav";
import AboutUs from "./pages/AboutUs";
import OurWork from "./pages/OurWork";
import ContactUs from "./components/ContactUs"
import Footer from "./components/Footer"
// Router
import { Routes, Route ,useLocation } from 'react-router-dom'
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <GlobalStyle/>
      <Nav/>
        <ScrollToTop />
        <Routes location={location} key={location.pathname}>
            <Route path="/"  element={<AboutUs/>}/>
            <Route path="/work"  element={<OurWork/>}/>
            <Route path="/contact" element={<ContactUs/>}/>
          </Routes>
        <Footer/>
    </div>
  );
}

export default App;
