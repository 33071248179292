import React from "react";

//Animations
import { motion } from "framer-motion";
import SectionWork from "../components/SectionWork.js"
import {pageAnimWork} from "../animation";
import TopSection from "../components/TopSection.js";

const OurWork = () => {

    return(
        <motion.div
        exit="exit"
        variants={pageAnimWork}
        initial="hidden"
        animate="show"
      >
        <div>
            <TopSection h1prop={"Visit our"} styleDisplayOn={"flex"} styleDisplayOff={"none"}/>
            <SectionWork/>
        </div>
        </motion.div>
    )
};



export default OurWork;
