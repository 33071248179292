import React from "react";
import styled from "styled-components";
//Images

//Animations
import { motion } from "framer-motion";
import nidexnone from "../img/nidexnone.png"
import {imageSlideWork, fadeAninWork , pageAnimWork} from "../animation";

const TopSection = ({h1prop ,styleDisplayOn , styleDisplayOff}) => {

    return(
        <motion.div
        exit="exit"
        variants={pageAnimWork}
        initial="hidden"
        animate="show"
      >
           <PrevSection>
                <motion.div variants={fadeAninWork}>
                    <h1>{h1prop}</h1>
                    <span style={{display:`${styleDisplayOff}`}}>together</span>

                    <ButtonContainer style={{display:`${styleDisplayOn}`}}>
                     <a href="https://www.amazon.co.uk/sp?ie=UTF8&seller=A3LSAICVO4P94J&isAmazonFulfilled=0&asin=B08RX3343D&ref_=olp_merch_name_1"><button >STORE</button></a>
                    </ButtonContainer>
                </motion.div>
                <motion.div variants={imageSlideWork}>
                    <img src={nidexnone}/>
                </motion.div>
            </PrevSection>

        </motion.div>
    )
};

export const PrevSection = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    height:100vh;
    padding: 10rem 10rem;
    h1,span{
        font-size:7rem;
        font-weight: 900;
        text-transform: uppercase;
    }
    span{
        background-color: #f3ec78;
        background-image:linear-gradient(to right, #204852 , #76c64b);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
    }
    img{
        width:370px;
        height:370px;

    }
    @media (max-width: 1300px) {
        h1,span{
            font-size:5rem;
        }
    }
    img{
        width:330px;
        height:330px;
    }
    @media (max-width: 1050px) {
    padding:2rem 2rem;
    height:100vh;
    img{
        width:35vw;
        height:35vw;
    }
    }
    @media (max-width: 800px) {
        h1,span{
        font-size:6vw;
        }
        img{
            width:35vw;
            height:35vw;
        }
    }
`
const ButtonContainer = styled.div`
   display:flex;
   justify-content:center;
   button{
    font-size:7rem;
    margin: 2rem 0;
    &:hover{
     background-image:linear-gradient(to right, #204852 , #76c64b);
    }
   }
   @media(max-width:1300px){
        button{
            font-size:6vw;
        }
    }
    @media(max-width:600px){
        button{
            margin: 1rem 0;
            padding:0.5rem 1rem;
        }
    }
`

const Title = styled.div`
    z-index:-1;
    top:0;
    right:0;
    width:100%;
    height:200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment:fixed;
    background-position: center ;
    h2{
        text-align: center ;
        line-height:200px;
        font-size:4rem;
        font-weight: 900;
        text-transform: uppercase;
        text-shadow: 1px 1px 20px black;
        backdrop-filter:brightness(80%);
    }
`


export default TopSection;
