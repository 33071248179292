import React from "react";
//Styles
import styled from "styled-components";
import {servicesCards , servicesCardItem } from "../animation";
import { useScroll } from "./useScroll";
import { pallete } from "./Pallete";
import { motion } from "framer-motion";

import servicesSectionData from "../serviceSectionData";
const ServicesSectionDescr = () => {
  const [element, controls] = useScroll();
  return (
      <Description>
        <Cards  
          variants={servicesCards}  
          animate={controls}
          initial="hidden"
          ref={element}
        >
            {
              servicesSectionData.map((elem,index) => {
                return(
                  <Card variants={servicesCardItem} key={index}>
                    <div className="icon">
                      <img alt="icon" src={elem.img} />
                      <h3>{elem.title}</h3>
                    </div>
                    <div>
                      <p>{elem.description}</p>
                    </div>
                  </Card>
                )
              })
            }
        </Cards>
      </Description>
  );
};

const Description = styled.div`
    flex: 1;
    z-index: 3;
    h2{
        font-weight: lighter;
    }
    @media (max-width: 1300px) {
        padding: 0;
        button {
        margin: 2rem 0rem 5rem 0rem;
        }
  }
`
const Cards = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 600px) {
      justify-content: space-between;
  }
  
`
const Card = styled(motion.div)`
  flex-basis: 45%;
  z-index: 10;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    
    img{
      width:50px;
      filter: ${pallete.UltraGreenFilter};
    }
    h3 {
      margin-left: 1rem;
      background:${pallete.White};
      color: ${pallete.Black};
      padding: 1rem;
      width:130px;
      text-align:center;
    }
  }
  div{
    display:flex; 
    justify-content: center;
    p{
        text-align:center;
        font-size:1.2rem;
    }
  }
  @media (max-width: 680px) {
    div{
      p{
        width: 80%;
      }
    }
  }
  @media (max-width: 533px) {
    flex-basis:100%;
  }
`

export default ServicesSectionDescr;
