import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import nidexnone from "../img/nidexnone.png"
import BurgerMenu from "./BurgerMenu";

const Nav = () =>{
  const { pathname } = useLocation();
    return(
        <>
        <StyledNav>
           
              <Link to="/" id="logo"><img src={nidexnone} alt="nidex_logo"/></Link>          
            <ul>
                <li>
                    <Link to="/" style={{ backgroundImage: pathname === "/" ? "linear-gradient(to right, #204852 , #76c64b)" : "linear-gradient(to right, white ,white )"}}>1. About Us</Link>     
                </li>
                <li>
                    <Link to="/work" style={{ backgroundImage: pathname === "/work" ? "linear-gradient(to right, #204852 , #76c64b)" : "linear-gradient(to right, white ,white )"}}>2. Our Work</Link>                   
                </li>
                <li>
                    <Link to="/contact" style={{ backgroundImage: pathname === "/contact" ? "linear-gradient(to right, #204852 , #76c64b)" : "linear-gradient(to right, white ,white )"}}>3. Contact Us</Link>                  
                </li>
            </ul>
        </StyledNav>
        <BurgerMenu />
        </>
    )
}

const StyledNav = styled.nav`
img{
    width:30px;
    height:30px;
}
    min-height: 10vh;
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 10rem;
    background: #282828;
    position: sticky !important;
    top: 0;
    z-index: 10;
    a{
        color:white;
        text-decoration: none;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
        background-color: white;
    }
    ul{
        display: flex;
        list-style: none;
    }
    #logo{
        font-size: 2rem;
    }
    li{
        padding-left: 10rem;
        position: relative;
    }
    @media (max-width: 1300px) {
      padding: 1rem 1rem;
      #logo {
        display: inline-block;
        margin: 1rem;
      }
      ul {
        padding: 2rem;
        justify-content: space-around;
        width: 100%;
        li {
          padding: 0;
        }
      }
    }
  @media (max-width: 460px) {
    ul {
        justify-content: space-between;
    }
  }
  @media (max-width: 380px) {
    display:none;
  }
`;


export default Nav;
