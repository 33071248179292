import React from "react";
import ShopBoy from "./ShopBoy";
import { About,Description,Image,Hide } from "../styles";
// Framer Motion
import {motion} from 'framer-motion';
import { titleAnim, fade, photoAnim } from "../animation";
import Wawe from "./Wawe"
import styled from "styled-components";
import { pallete } from "./Pallete";

const AboutSection = () =>{
    return(
        <About>
            <Description>
                <motion.div>
                    <Hide>
                        <motion.h2 variants={titleAnim} >Nidex Limited OU is your </motion.h2>
                    </Hide>
                    <Hide>
                        <motion.h2 variants={titleAnim} >ultimate <span>partner</span> in selling</motion.h2>
                    </Hide> 
                    <Hide>
                        <motion.h2 variants={titleAnim} >consumer electronics  and computer components</motion.h2>
                    </Hide> 
                    <Hide>
                        <motion.h2 variants={titleAnim}> on Amazon</motion.h2>
                    </Hide>
                    <motion.p variants={fade}>Please visit our Amazon Store for our latest offers and explore the range of goods we are selling.</motion.p>
                    <div>
                        <a href="https://www.amazon.co.uk/sp?ie=UTF8&seller=A3LSAICVO4P94J&isAmazonFulfilled=0&asin=B08RX3343D&ref_=olp_merch_name_1"><MoButton variants={fade}>STORE</MoButton></a> 
                    </div>
                </motion.div>
            </Description>
            <Image>
                <motion.div variants={photoAnim}>
                    <ShopBoy />  
                </motion.div>
            </Image>
            <Wawe/>
        </About>
    )
}

const MoButton = styled(motion.button)`
  a{
    color: ${pallete.White};
    font-family: 'Inter', sans-serif;
    text-decoration: none;
    }
    @media(max-width:800px){
        padding:0.5rem 1rem;
    }
    @media(max-width:600px){
        padding:1rem 2rem;
    }
`;





export default AboutSection;
