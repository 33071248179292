import { createGlobalStyle } from "styled-components"
import { pallete } from "./Pallete";
const GlobalStyle = createGlobalStyle`

    *{
        margin:0;
        padding:0;
        box-sizing:border-box;


        ::-webkit-scrollbar-track {
	        background-image:linear-gradient(to top, #204852 , #76c64b); 
        } 
        ::-webkit-scrollbar-thumb { 
            border-radius: 5px; 
            background-color: #ccc;     
        } 
        ::-webkit-scrollbar { 
            width: 6px; 
        }
        scrollbar-color: linear-gradient(to top, #204852 , #76c64b) , #ccc ;
	    scrollbar-width: 6px; 
    }
    html{
        @media (max-width: 1700px){
            font-size: 75%;
        }
        @media (max-width: 1050px){
            font-size: 67%;
        }   
    }

    h1,h2,h3,h4,p,a{
        color: ${pallete.White};
        letter-spacing: 1px;
    }
    body{
        background: ${pallete.BackGround};
        font-family: 'Inter', sans-serif;
        overflow-x: hidden;
    }
    button{
        font-weight: bold;
        font-size: 1.1rem;
        cursor: pointer;
        padding: 1rem 2rem;
        border: 3px solid ${pallete.UltraGreen};
        background: transparent;
        color: ${pallete.White};
        transition: all 0.5s ease;
        font-family: 'Inter', sans-serif;
        &:hover{
            background-color: ${pallete.UltraGreen};
            color: ${pallete.White};
            text-shadow: 1px 1px 7px ${pallete.BackGround};
        }
    }
    h2{
        font-weight: lighter;
        font-size: 4rem;
    }
    h4{
        font-weight: bold;
        font-size: 2rem;    
    }
    a{
        font-size: 1.1rem;
    }
    span{
        font-weight: bold;
        background-color: #f3ec78;
        background-image:linear-gradient(to right, #204852 , #76c64b);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;

    }
    p{
        padding: 3rem 0rem;
        color: ${pallete.LightGray};
        font-size: 1.4rem;
        line-height: 150%;
    }
    
 
`
export default GlobalStyle;
