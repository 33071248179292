
import React from "react";

import { pallete } from "./Pallete";
import styled from "styled-components";
import chip from "../img/chip.jpg"


const DescriptionSection = () =>{

    return(
        <>
            <Paralax >
            </Paralax>
            <Container>
                <h2>
                    <span>NIDEX LIMITED OU</span> is an Estonian Tallinn-based company that is specialising in a retail sales on 
                    Amazon. 
                </h2>
                
                    <p>
                        Nidex Limited OU staff is combining over 50 years of joined experience in electronics trading 
                        industry. We are proud of our deep knowledge of electronics trading industry and our contacts 
                        gained over the years of successful trading. 
                    </p>
                <div>
                    <p>
                        We are now expanding our operation on European market, and taking Amazon Europe by storm. Our 
                        online Amazon store is offering to our customers best prices for the computer components and 
                        consumer electronics. 
                    </p>        
                    <p>
                        Our Estonian operation centre is covering sales on all main European Amazon sites and dispatch and 
                        customs clearing of the goods covered by European operation.
                    </p>
                    <p>
                        Central European Location together 
                        with supportive legal and taxation makes Nidex Limited OU your ideal partner for all your electronics 
                        requests or offers. Please contact us via our Amazon Store or using our contact email.
                    </p>
                    <ContainerButton>
                            <a href="https://www.amazon.co.uk/sp?ie=UTF8&seller=A3LSAICVO4P94J&isAmazonFulfilled=0&asin=B08RX3343D&ref_=olp_merch_name_1">
                                <button >STORE</button>
                            </a>
                    </ContainerButton>
                </div>
            </Container>
    </>
    )
}


const Container = styled.div`
    padding: 5rem 10rem;
    color:${pallete.White};
    z-index:2;
    h2{
        font-weight: lighter;
        font-size:3vw;
    }
    >div{
        display:flex;
        flex-wrap:wrap;
        justify-content: space-between;
        
        p{
            width:40%;
            padding:0;
            margin-bottom:3rem;

        }
    }



    @media (max-width: 1280px) {
    padding: 3rem 3rem;
    }
    @media (max-width: 768px) {
        h2{
        font-size:6vw;
        }
     >div{
        flex-direction:column;
        p{
            width:100%;
        }
    }
    }
    @media (max-width: 600px) {
      padding: 6rem 3rem 5rem ;
    }
    @media (max-width: 400px) {
      padding: 10vh 3rem 5rem;
      h2{
        font-size:6.7vw;
        }
    }
`

const ContainerButton = styled.div`
    width:40%;
    display:flex;
    justify-content:center;
    button{
        font-size:5rem;
        a{
            color: ${pallete.White};
            text-decoration: none;
        
        }
        @media(max-width:800px){
            padding:0.5rem 1rem;
        }
        @media(max-width:600px){
            padding:1rem 2rem;
        }
        &:hover{
            background-image:linear-gradient(to right, #204852 , #76c64b);
        }
    }
    @media (max-width: 768px) {
        width:100%;
    }
`

const Paralax = styled.div`
    z-index:-1;
    width:100%;
    height:150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${chip});
    background-attachment:fixed;
`
export default DescriptionSection;